<template>
  <Dashboard :profileID="configDashboard.profile_id" />
</template>
<script>
  import Profiles from '@/config/profiles'
  import Dashboard from '@/components/inc/dashboard/dashboard'

  export default {
    components: {
      Dashboard
    },
    data: () => {
      return {
        configDashboard: {
          profile_id: Profiles.PROVEEDOR,
        },
      }
    },
  }
</script>
